import React, { Component } from 'react'
import { Link } from 'gatsby'
import { kluwer, moneybird, exact, yuki, eboekhouden, informer, snelstart, rompslomp, werktijden, visma, shiftbase, tkp, azl, agh, bpl, pfzw} from 'images'
import { keeping, reeleezee, gauth, dyflexis, treams, eitjeLogoWhite, asrLogo, jorttLogo, pensifyLogo } from 'images/koppelingen'

import Layout from '../../../layouts'
import Helmet from 'react-helmet'

import Koppeling, { KoppelingRow } from 'components/koppeling'

/* #region dummy data */
const dummyDataMoneybird = {
  image: moneybird,
  eyebrow: 'Exporteren van journaalpost',
  title: 'Moneybird',
  category: 'Boekhoudpakket',
  class: 'moneybird small',
  content: '',
  link: '/product/koppelingen/moneybird/'
}

const dummyDataYuki = {
  image: yuki,
  eyebrow: 'Exporteren van journaalpost',
  title: 'Yuki',
  category: 'Boekhoudpakket',
  class: 'yuki small',
  content: '',
  link: '/product/koppelingen/yuki/'
}

const dummyDataKluwer = {
  image: kluwer,
  eyebrow: 'Exporteren van journaalpost',
  title: 'Twinfield',
  category: 'Boekhoudpakket',
  class: 'kluwer small',
  content: '',
  link: '/product/koppelingen/kluwer/'
}

const dummyDataExact = {
  image: exact,
  eyebrow: 'Exporteren van journaalpost',
  title: 'Exact',
  category: 'Boekhoudpakket',
  class: 'exact small',
  content: '',
  link: '/product/koppelingen/exact/'
}

const dummyDataEboekhouden = {
  image: eboekhouden,
  eyebrow: 'Exporteren van journaalpost',
  title: 'e-Boekhouden.nl',
  category: 'Boekhoudpakket',
  class: 'eboekhouden small',
  content: '',
  link: '/product/koppelingen/e-boekhouden/'
}

const dummyDataInformer = {
  image: informer,
  eyebrow: 'Exporteren van journaalpost',
  title: 'Informer',
  category: 'Boekhoudpakket',
  class: 'informer small',
  content: '',
  link: '/product/koppelingen/informer/'
}

const dummyDataSnelStart = {
  image: snelstart,
  title: 'Snelstart',
  category: 'Boekhoudpakket',
  class: 'snelstart small',
  content: '',
  link: '/product/koppelingen/snelstart/'
}

const dummyDataWerktijden = {
  image: werktijden,
  title: 'Werktijden.nl',
  category: 'Planning & urenregistratie',
  class: 'werktijden small',
  content: '',
  link: '/product/koppelingen/werktijden/'
}

const dummyDataReeleezee = {
  image: reeleezee,
  title: 'Reeleezee',
  category: 'Boekhoudpakket',
  class: 'reeleezee small',
  content: '',
  link: '/product/koppelingen/reeleezee/'
}

const dummyDataRompslomp = {
  image: rompslomp,
  title: 'Rompslomp',
  category: 'Boekhoudpakket',
  class: 'rompslomp small',
  content: '',
  link: '/product/koppelingen/rompslomp/'
}

const dummyDataVisma= {
  image: visma,
  title: 'eAccounting',
  category: 'Boekhoudpakket',
  class: 'visma small',
  content: '',
  link: '/product/koppelingen/visma-eaccounting/'
}

const dummyDataShiftbase = {
  image: shiftbase,
  title: 'Shiftbase',
  category: 'Planning & urenregistratie',
  class: 'shiftbase small',
  content: '',
  link: '/product/koppelingen/shiftbase/'
}

const dummyDataDyflexis = {
  image: dyflexis,
  title: 'Dyflexis',
  category: 'Planning & urenregistratie',
  class: 'dyflexis small',
  content: '',
  link: '/product/koppelingen/dyflexis/'
}

const dummyDataEitje = {
  image: eitjeLogoWhite,
  title: 'eitje',
  category: 'Planning & urenregistratie',
  class: 'eitje small',
  content: '',
  link: '/product/koppelingen/eitje/'
}


const dummyDataKeeping = {
  image: keeping,
  title: 'Keeping',
  category: 'Planning & urenregistratie',
  class: 'keeping small',
  content: '',
  link: '/product/koppelingen/keeping/'
}

const dummyDataTkp = {
  image: tkp,
  title: 'Tkp',
  class: 'tkp small',
  category: 'Pensioenfonds detailhandel',
  link: '/product/koppelingen/tkp/'
}

const dummyDataAzl= {
  image: azl,
  title: 'Azl',
  category: 'Pensioenuitvoerder',
  class: 'azl small',
  link: '/product/koppelingen/azl/'
}

const dummyDataPfzw= {
  image: pfzw,
  title: 'Zorg en welzijn',
  category: 'Pensioenuitvoerder',
  class: 'pfzw small',
  link: '/product/koppelingen/pfzw/'
}

const dummyDataGoogleAuth= {
  image: gauth,
  title: 'Google Authenticator',
  category: 'Tweestapsverificatie (2FA)',
  class: 'gauth small',
  link: '/product/koppelingen/google-authenticator/'
}

const dummyDataTreams= {
  image: treams,
  title: 'Treams',
  category: 'Performance management',
  class: 'treams small',
  link: '/product/koppelingen/treams/'
}

const dummyDataAsr= {
  image: asrLogo,
  title: 'a.s.r. Inkomen',
  category: 'Verzekeringen',
  class: 'asr small',
  link: '/product/koppelingen/asr-inkomen/'
}

const dummyDataJortt= {
  image: jorttLogo,
  title: 'Jortt',
  category: 'Boekhoudpakket',
  class: 'jortt small',
  link: '/product/koppelingen/jortt/'
}

const dummyDataPensify= {
  image: pensifyLogo,
  title: 'Pensify',
  category: 'Pensioenoplossing',
  class: 'pensify small',
  link: '/product/koppelingen/pensify/'
}

/* #endregion */

class KoppelingenPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  scrollToFAQSection(id) {
    const target = document.querySelector(`#${id}`)
    const scrollToTarget = target.offsetTop - 32
    window.scrollTo(0, scrollToTarget)
  }

  handleScroll() {
    const scrollPos = window.pageYOffset
    const cats = document.getElementById('categories').childNodes

    cats.forEach((item) => {
      const target = item.dataset['target']
      const refElem = document.getElementById(target)
      const refTop = refElem.offsetTop - 120
      const refHeight = refElem.getBoundingClientRect().height + 128

      if (refTop <= scrollPos && refTop + refHeight > scrollPos) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }

  render() {
    const { location } = this.props

    return (
      <Layout location={location}>
        <main className="koppelingen animated fadeInPage">

          <Helmet>
            <title>Koppel salarisadministratie met boekhouding en urenregistratie</title>
            <meta
              name="description"
              content="Employes maakt de salarisadministratie makkelijk dankzij koppelingen met Moneybird, Exact Online en Yuki. 30 dagen gratis!"
            />

            <meta
              itemprop="name"
              content="Koppel salarisadministratie met boekhouding en urenregistratie"
            />

            <meta
              itemprop="description"
              content="Employes maakt de salarisadministratie makkelijk dankzij koppelingen met Moneybird, Exact Online en Yuki. 30 dagen gratis!"
            />

            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="page-title padding-xxl">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-8">
                  <h1>Koppel Employes met andere software</h1>
                  <p className="streamer large center">We bieden uitgebreide koppelingen aan met de meest populaire software voor boekhouden, urenregistratie en pensioenaangiftes.</p>
                </div>
              </div>
            </div>
          </header>

          <section className="overview koppeling-overview padding-xxl">
            <div className="container-md">
              <div className="grid">
                <div className="col-3">
                  <p className="eyebrow">Categorieën</p>

                  <ul className="categories" id="categories">

                    <li
                      data-target="twofactor"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('twofactor')}
                    >
                      <h6>Authenticatie</h6>
                    </li>
                    <li
                      data-target="boekhouding"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('boekhouding')}
                    >
                      <h6>Boekhouden</h6>
                    </li>
                    <li
                      data-target="pensioen"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('pensioen')}
                    >
                      <h6>Pensioenaangifte</h6>
                    </li>
                    <li
                      data-target="performance-management"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('performance-management')}
                    >
                      <h6>Performance management</h6>
                    </li>
                    <li
                      data-target="urenregistratie"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('urenregistratie')}
                    >
                      <h6>Planning & urenregistratie</h6>
                    </li>
                    <li
                      data-target="verzekeringen"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('verzekeringen')}
                    >
                      <h6>Verzekeringen</h6>
                    </li>
                  </ul>
                </div>

                <div className="col-9">
                  <div className="koppeling-section" id="twofactor">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                        Beveiliging van je gegevens
                        </p>

                        <h1 className="koppeling-heading-category">
                          Authenticatie
                        </h1>

                        <p className="koppeling-heading-description">
                          De veiligheid van je gegevens vinden we heel belangrijk. Daarom bieden we de optie aan om je account extra beveiligen met tweestapsverificatie (2FA). Hiervoor kun je verschillende mobiele applicaties, zoals Google Authenticator of Authy gebruiken.
                          </p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataGoogleAuth} button={true} />
                        </div>
                        <div className="col-6">

                        </div>
                      </div>
                    </KoppelingRow>

                  </div>

                  <div className="koppeling-section" id="boekhouding">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                          Exporteren journaalposten
                        </p>

                        <h1 className="koppeling-heading-category">
                          Boekhouden
                        </h1>

                        <p className="koppeling-heading-description">
                          Om de kosten van je personeel automatisch te verwerken in je boekhouding, kun je Employes koppelen met verschillende boekhoudprogrammas.
                          </p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                        <Koppeling data={dummyDataMoneybird} button={true} />

                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataEboekhouden} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataExact} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataYuki} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataSnelStart} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataKluwer} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                        <Koppeling data={dummyDataInformer} button={true} />

                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataReeleezee} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataRompslomp} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataVisma} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataJortt} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                  </div>

                  <div className="koppeling-section" id="pensioen">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                          Automatische pensioenaangifte
                        </p>

                        <h1 className="koppeling-heading-category">
                          Pensioenaangifte
                        </h1>

                        <p className="koppeling-heading-description">
                          Employes koppelt met een aantal van de grootste pensioenuitvoerders waardoor de pensioenaangifte geheel automatisch plaats vindt.
                          </p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataAzl} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataTkp} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataPfzw} button={true} />
                        </div>
                        <div className="col-6">
                        <Koppeling data={dummyDataPensify} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">

                        </div>

                      </div>
                    </KoppelingRow>
                  </div>

                  <div className="koppeling-section" id="performance-management">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                          Werknemergegevens uitwisselen
                        </p>

                        <h1 className="koppeling-heading-category">
                          Performance management
                        </h1>

                        <p className="koppeling-heading-description">Ben je klaar om je HR- en salarisadministratie uit te breiden met performance management? Ontdek dan de integratie tussen Treams en Employes.</p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataTreams} button={true} />
                        </div>
                        <div className="col-6">

                        </div>
                      </div>
                    </KoppelingRow>

                  </div>

                  <div className="koppeling-section" id="urenregistratie">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                          Importeer gewerkte uren
                        </p>

                        <h1 className="koppeling-heading-category">
                        Planning & urenregistratie
                        </h1>

                        <p className="koppeling-heading-description">
                          Koppel je urenregistratie om automatisch de gewerkte uren te importeren in Employes. Dat scheelt overtypen en voorkomt fouten.
                          </p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">

                          <Koppeling data={dummyDataWerktijden} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataShiftbase} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataEitje} button={true} />
                        </div>
                        <div className="col-6">
                          <Koppeling data={dummyDataKeeping} button={true} />
                        </div>
                      </div>
                    </KoppelingRow>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataDyflexis} button={true} />
                        </div>
                        <div className="col-6">

                        </div>
                      </div>
                    </KoppelingRow>

                  </div>


                  <div className="koppeling-section" id="verzekeringen">
                    <div className="grid">
                      <div className="col-9">
                        <p className="eyebrow">
                        Werknemergegevens uitwisselen
                        </p>

                        <h1 className="koppeling-heading-category">
                        Verzekeringen
                        </h1>

                        <p className="koppeling-heading-description">
                          Koppel je salarisadministratie aan je verzuimverzekering via a.s.r.
                        </p>
                      </div>
                    </div>

                    <KoppelingRow>
                      <div className="grid">
                        <div className="col-6">
                          <Koppeling data={dummyDataAsr} button={true} />
                        </div>
                        
                      </div>
                    </KoppelingRow>

                  </div>


                </div>
              </div>
            </div>
          </section>

          <section className="cta">
            <div className="container-sm">
              <div className="cta-row padding-l margin-xxl-bottom light">
                <div className="container-md">
                  <div className="grid center text-center">
                    <div className="col-10">
                      <h6 className="eyebrow">Employes API</h6>
                      <h2>Integreer met Employes</h2>
                      <p className="cta-text">
                        We bieden een moderne API aan waarmee je bijvoorbeeld eenvoudig werknemers toevoegt en het resultaat van een verloning kunt opvragen.
                      </p>
                    </div>
                    <div className="col-6 margin-m-top sign-up-container">
                      <div className="sign-up">
                        <a href="https://developer.employes.nl/" target="_blank" className="link blue" rel="nofollow">Bekijk API-documentatie</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default KoppelingenPage
