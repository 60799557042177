import React from 'react'
import { Link } from 'gatsby'

export const KoppelingRow = ({ children }) => (
  <div className="koppeling-row">
    {children}
  </div>
)

export default (props) => (
  <div className={`koppeling ${props.data.class}`}>
    { props.recommended === true ?
      <div className="recommended">Aanbevolen</div>
      :
      null
    }

    <div className="logo-container">
      <img src={props.data.image} alt="logo" />
    </div>

    <div className="koppeling-content">
      <h2 className="koppeling-heading">{props.data.title}</h2>
      <p className="koppeling-category">{props.data.category}</p>

      <p className="koppeling-description">{props.data.content}</p>

      { props.button === true ? <Link to={props.data.link} className="btn secondary lg">Lees meer</Link>   :
        null }
    </div>
  </div>
)
